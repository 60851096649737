export const CertificationData = [
    {
      title: "Medical Interpreting",
      detail:
        "Medical interpreters are part-and-parcel of critical care, providing necessary services for those who are in need of medical communications. They are essential workers who go back-and-forth from patient to provider with interpretation services, in hospitals, in home care, in rehabilitation facilities, and in emergency situations. Medical interpreters can be the bridge between the language barrier and essential critical care.",
    },
    {
      title: "Legal Interpreting",
      detail:
        "For the legal field, legal interpreters convey messages from a lawyer or judge to the person or entity. Legal interpreters work in courtrooms, law offices, and other legal settings. One mistake in interpretation may cost a person's life, so interpreting services are necessary for the legal field. At Tomedes, legal interpreters are well-versed in the law as well as two or more languages.",
    },
    {
      title: "Interpreting for Businesses",
      detail:
        "During a remote webinar, or a physical conference, or a simple business meeting, interpreters for business carry the special role of a go-between from one business representative to another. They're envoys that relay messages about negotiations, deals, and more, making their role important for global businesses.",
    },
    {
      title: "For Personal Use",
      detail:
        "Personal interpreting for small businesses or individuals can be attained at Tomedes in over 120 languages and 950+ language pairs. Personal interpreters present results in understandable terms from one language to another to their clients. Individuals who would like a personal interpreter can avail of one at Tomedes, with our 24/7 customer line always open.",
    },
    {
      title: "Conferences and Seminars",
      detail:
        "Simultaneous or consecutive interpretation for conferences and seminars usually require more than one interpreter. Interpreting is important in relaying information of the ongoing events, the directions, the program, and the events themselves, for conferences and seminars.",
    },
    {
      title: "Sign Language  Interpretation (ASL etc.)",
      detail:
        "Sign language interpretation is important in providing services to the disabled, the deaf or the hard of hearing. Tomedes provides sign language interpreters proficient in American Sign Language and any other system of sign language interpretation.",
    },
  ];