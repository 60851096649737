import React, { useState, useRef } from "react";
import { useInterpretationService } from "hooks/Forms/useInterpretation";
import { useForm } from "react-hook-form";
import { useFetchLanguages } from "hooks/Quotes/useFetchLanguages";
import Loader from "components/loader";
import Modal from "components/popup";
import HCaptcha from "@hcaptcha/react-hcaptcha";
import formCommonPostData from "utils/form-common-post-data";
import { errorMessageEmail, patternEmail } from "utils/form-validation";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export default function FirstComp() {
  const [startDate, setStartDate] = useState(new Date());
  const [open, setOpen] = useState(false);
  const [token, setToken] = useState(false);
  const captchaRef = useRef(null);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const { status, mutateAsync } = useInterpretationService();
  const { data } = useFetchLanguages();

  const onSubmit = (data) => {
    // console.log("helllo");
    if (token) {
      const postData1 = {
        interpretation_type: data.type,
        project_address: data.address,
        how_long_is_it_taking: data.duration,
        source_language: data.fromLang,
        target_langugae: data.toLang,
        email: data.email,
        name: data.name,
        more_about_needs: data.message,
        order_date: moment(startDate).format("YYYY-MM-DD"),
      };
      const postData = { ...postData1, ...formCommonPostData() };
      mutateAsync(postData).then((_) => {
        reset();
        setOpen(true);
      });
    } else {
      alert("Please verify captcha");
    }
  };

  const handleVerify = () => {
    setToken(true);
  };

  return (
    <div className="bg-midBlue" id="form">
      <form
        className="mainContainer lg:w-10/12 2xl:w-7/12 w-full mx-auto px-6 justify-center text-white lg:relative py-20 pb-36"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Modal open={open} setOpen={setOpen} />

        <h1 className="font-bold text-4xl">
          <span className="text-orange">Interpreting </span>services
        </h1>
        <p className="leading-loose py-8 font-light">
          Tomedes offers interpretation services in almost every industry
          imaginable, for various use cases, and in different types of
          interpretation, in order to convey language services, from businesses
          to individuals, in over 120 languages and 950+ language pairs. We
          provide interpretation services with a 1-year guarantee, rapid
          delivery, and 24/7 customer support.
        </p>
        <div className="border-b border-light"></div>
        <p className="font-extralight leading-loose py-6">
          Scheduling an appointment has never been easier at Tomedes.
        </p>

        <div className="form grid lg:grid-cols-3 gap-8 w-full al items-start">
          <div className="1">
            <label htmlFor="" className="flex flex-col">
              Select Interpretation Type
              <select
                id="interpret_type"
                name="interpret_type"
                class="drop_down bg-white text-[#9e9e9e] py-[10px] px-4 pr-10 mt-4 rounded h-10"
                {...register("type")}
              >
                <option value="Radio-0" selected="">
                  Select interpretation type
                </option>
                <option value="conference">Conference</option>
                <option value="phone-interpreting">Phone Interpreting</option>
                <option value="arbitration">Arbitration</option>
                <option value="mediation">Mediation</option>
                <option value="medical-exam">Medical Exam</option>
                <option value="court">Court</option>
                <option value="immigration">Immigration</option>
                <option value="deposition">Deposition</option>
                <option value="Radio-1">Other</option>
              </select>
              {/* {errors.type && (
                <span className="text-red">{errorMessage}</span>
              )} */}
            </label>
          </div>{" "}
          <div className="1">
            <label htmlFor="" className="flex flex-col">
              Address of Project
              <input
                name="address"
                type="text"
                placeholder="Address of Project"
                className="py-2 px-4 pr-10 mt-4 text-black rounded"
                // {...register("address")}
                {...register("address", { required: true })}
                id="address"
              />
              {/* {errors.address && (
                <span className="text-red">{errorMessage}</span>
              )} */}
            </label>
          </div>{" "}
          <div className="1">
            <label htmlFor="" className="flex flex-col">
              When is it going to happen?
              <DatePicker
                required={true}
                selected={startDate}
                minDate={new Date()}
                dateFormat="dd-MM-yyy"
                onChange={(date) => setStartDate(date)}
                placeholderText="When is it going to happen?"
                className="py-2 px-4 pr-10 mt-4 text-[#9e9e9e] bg-white w-full rounded"
              />
              {/* {errors.order_date && (
                <span className="text-red">{errorMessage}</span>
              )}{" "} */}
            </label>
          </div>{" "}
          <div className="1">
            <label htmlFor="" className="flex flex-col">
              How long is it going to take?*
              <input
                type="text"
                placeholder="How long is it going to take?"
                className="py-2 px-4 pr-10 mt-4 rounded text-black"
                {...register("duration", { required: true })}
                id="duration"
                name="duration"
              />
              {/* {errors.duration && (
                <span className="text-red">{errorMessage}</span>
              )}{" "} */}
            </label>
          </div>{" "}
          <div className="1">
            <label htmlFor="" className="flex flex-col">
              Select Source Language
              <select
                className=" bg-white text-[#9e9e9e] py-[10px] px-4 pr-10 mt-4 rounded h-10"
                {...register("fromLang", { required: true })}
                id="fromLang"
                name="fromLang"
              >
                <option disabled selected>
                  Select Source Language
                </option>
                {data
                  ? data?.data?.map(({ source_language }) => (
                      <option className="py-2 px-4 pr-10 mt-4 text-[#9e9e9e] bg-white w-full rounded">
                        {source_language}
                      </option>
                    ))
                  : null}
              </select>
              {/* {errors.fromLang && (
                <span className="text-red">{errorMessage}</span>
              )}{" "} */}
            </label>
          </div>{" "}
          <div className="1">
            <label htmlFor="" className="flex flex-col">
              Select Target Language
              <select
                className=" bg-white text-[#9e9e9e] py-[10px] px-4 pr-10 mt-4 rounded h-10"
                {...register("toLang", { required: true })}
                id="toLang"
                name="toLang"
              >
                <option disabled selected>
                  Select Target Language
                </option>
                {data
                  ? data?.data?.map(({ source_language }) => (
                      <option className="py-2 px-4 pr-10 mt-4 text-[#9e9e9e] bg-white w-full rounded">
                        {source_language}
                      </option>
                    ))
                  : null}
              </select>
              {/* {errors.toLang && (
                <span className="text-red">{errorMessage}</span>
              )}{" "} */}
            </label>
          </div>{" "}
          <div className="1">
            <label htmlFor="" className="flex flex-col">
              Email
              <input
                type="email"
                placeholder="Email Address"
                className="py-2 px-4 pr-10 mt-4 rounded text-black"
                {...register("email", {
                  required: true,
                  pattern: patternEmail,
                })}
                id="email"
                name="email"
              />
              {errors.email && (
                <span className="text-red">{errorMessageEmail}</span>
              )}
            </label>
          </div>{" "}
          <div className="1">
            <label htmlFor="" className="flex flex-col">
              Your Name
              <input
                type="text"
                placeholder="Your Name"
                className="py-2 px-4 pr-10 mt-4 rounded text-black"
                {...register("name", { required: true })}
                id="name"
                name="name"
              />
              {/* {errors.name && (
                <span className="text-red">{errorMessage}</span>
              )}{" "} */}
            </label>
          </div>
        </div>
        <div className="div pt-4 grid-cols-2 lg:grid-cols-3 grid gap-8 items-end">
          <div className="col-span-2">
            <div>
              <label htmlFor="" className="block">
                Tell us more about your needs
              </label>
              <textarea
                className="rounded text-black mt-3 w-full"
                cols="60"
                rows="3"
                {...register("message")}
                id="message"
                name="message"
              ></textarea>
              {/* {errors.message && (
            <span className="text-red">{errorMessage}</span>
          )} */}
            </div>
          </div>
          <div style={{ width: "320px" }} className="relative">
            <HCaptcha
              sitekey="b6b06718-08ae-457f-a6d7-147046b4c216"
              onVerify={handleVerify}
              ref={captchaRef}
            />
            <input type="hidden" id="zc_gad" name="zc_gad" value="" />
            <button
              type="submit"
              className="px-9 py-3 mt-4 font-sans  rounded text-white bg-lightBlue hover:bg-newLightBlue absolute lg:right-4"
            >
              {status === "loading" ? <Loader /> : "Submit"}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}
