import Layout from "components/layout";
import InterpretationServices from "components/reusableComponents/interpretationServices";
import SEO from "components/seo";
import React from "react";

export default function index() {
  const title = "Professional Interpretation Services - Tomedes";
  const description =
    "Tomedes translation services provides high quality interpretation services, including simultaneous interpretation, consecutive interpretation and conference interpretation. We're available 24/7. Quality-guaranteed service.";
  const keywords = "";
  return (
    <Layout>
      <SEO
        title={title}
        description={description}
        keywords={keywords}
        slug="/interpretation-services"
      />
      <InterpretationServices />
    </Layout>
  );
}
