import Reviews from "components/homepage/reviews";
import WhatChooseUs from "components/homepage/whyChooseUs";
import SpeceficTranslation from "components/LocationReusableComp/speceficTranslation";
import TrustedTranslation from "components/LocationReusableComp/trustedtranslation";
import React from "react";
import Assuredservice from "../assuredservice";
import Birthcomp from "../BirthCertificate/Birthcomp";
import VariousTranslation from "../VariousTranslation";
import WhatsAppComp from "../whatsappComp";
import { CertificationData } from "./config";
import FirstComp from "./firstComp";
import img4 from "assets/images/interpretation-services-assets/videoremote.svg";
import img5 from "assets/images/interpretation-services-assets/onsite.svg";

export default function InterpretationServices() {
  return (
    <>
      <FirstComp />
      <VariousTranslation
        backgroundCol="bg-darkBlue"
        ftitle=""
        para="Whether it's medical interpreting, legal interpreting or more, interpretation services can be used for almost every industry imaginable."
        title="Interpreting services for every use case "
        data={CertificationData}
      />
      <Assuredservice
        backgroundCol="bg-midBlue"
        colorHead="Interpretation"
        whiteHead="services the way you want it"
        h1="Over the phone interpreting"
        h2="Simultaneous Interpretation"
        h3="Consecutive Interpretation"
        h4="Video Remote Interpreting"
        h5="On-site Interpreting"
        p1="Over the phone language interpretation services, or simply, phone interpretation services, is a three way communication between a speaker, a listener and an interpreter. Over the phone language interpreters will assist and provide consecutive interpreting services, whether it be for conventions, Zoom meetings, and more."
        p2="Simultaneous interpretation is a mode of interpreting that allows an interpreter to translate a speaker's words directly, without needing for the speaker to pause. As a simultaneous interpreting company, Tomedes is highly equipped to provide foreign language interpreters in over 120 languages."
        p3="Consecutive interpretation is the mode of interpreting that allows the speaker and the interpreter to take turns speaking, and then translating. The professional interpreters at Tomedes have the advantage of knowing both consecutive and simultaneous interpretation, providing linguistic expertise for both types of interpretation."
        p4="Video remote interpreting is a vital part of video interpretation services, and available at Tomedes. Video remote interpreting is the process of providing language or sign language interpretation over video, using video equipment in both locations. At Tomedes, video remote interpreters provide essential services to those with limited language proficiency in the source language."
        p5="On-site interpreting services allows the interpreter to be with the speaker on the site, whether it be an event or an emergency. In contrast to telephonic interpretation services, on-site interpreting services have to interpret on the event or conference itself, which provides a live interpretation line for essential language services."
        img4={img4}
        img5={img5}
        linkName
        scrollTo="#form"
      />
      <WhatsAppComp
        backgroundCol="bg-darkBlue"
        fHead="Tell us"
        secondHead="what you think!"
        fHeadCol="text-lightBlue"
        content="If you have remaining questions, thoughts, or doubts about the process of interpreting, make sure to leave us a message below."
        whatsUp
      />
      <TrustedTranslation
        backgroundCol="bg-midBlue"
        headColor="text-orange"
        ColorHead="Secure"
        SecondWhite="and compliant interpretation services"
        Content="You can be assured that your information will always be protected and remain confidential at Tomedes. For medical interpreting, we are HIPAA-compliant. For all our interpreting services, we use NDAs for our translators and our project managers to ensure that your information remains secure. We also use end-to-end encryption in the platforms of your choice, for any interpreting service, or for sending documentation to us."
      />
      <SpeceficTranslation
        FirstOrange=" "
        FirstWhite="A"
        ColorHead="network"
        SecondWhite="of professional interpreters ready to provide the service you need"
        headColor="text-lightBlue"
        linkName="SEE OUR SUPPORTED LANGUAGES"
        Content="TAt Tomedes, we are proud of our global pool of professional interpreters who are strategically employed to be in different time zones, so they can provide the best service possible, at any time, anywhere. We make it our mission to hire the best interpreters in the world, knowing that interpreting is a language bridge between a speaker and a listener, eliminating language barriers that may occur for those with limited skill in a target language. Our senior linguists have the training and accreditation to interpret as truly and accurately as they can. And, our account managers ensure that from the start of every project you are matched with the professional team most suited to successfully complete your interpreting and translation services."
        backgroundCol="bg-darkBlue"
        link=" "
      />
      <Birthcomp
        colorHead="text-orange"
        midHead="Interpreting"
        secondHead="Available in 120 Languages and 950+ Language Pairs"
        para="You couldn't ask for more than 120 languages and 950+ language pairs from our interpreting services. If you need Russian interpretation services, Arabic interpretation services, or interpretation in any other language, just let us know. For our list of comprehensive languages, follow this link."
        linkName
        firstHead=" "
        backgroundCol="bg-interpret-banner"
      />
      <WhatChooseUs />
      <Reviews
        backgroundCol="bg-darkBlue"
        textcol="text-white"
        socialIconsVisible={true}
      />
    </>
  );
}
