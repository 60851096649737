import React from "react";
import Bank from "assets/images/certified-translation/ATA.svg";
import Public from "assets/images/certified-translation/PublicandPrivate.svg";
import Six from "assets/images/certified-translation/SixContinents.svg";
export default function Assuredservice({ backgroundCol, colorHead, whiteHead, h1, h2, h3, h4, h5, p1, p2, p3, p4, p5, img4, img5, linkName, scrollTo=false }) {
  return (
    <div className={backgroundCol ? backgroundCol : "bg-darkBlue"}>
      <div className="mainDiv flex flex-wrap lg:px-0 px-4 mx-auto lg:justify-center justify-start lg:w-10/12 w-full py-24 gap-x-32 pb-0 max-w-6xl">
        <div className="leftText text-white lg:w-4/12 w-full">
          <h2 className="text-4xl font-bold font-secondary">
            <span className="text-orange">{colorHead ? colorHead : "Certified"}</span> {whiteHead ? whiteHead : "Translation Services You Can Be Assured Of"}
          </h2>
        </div>
        <div className="rightComponent text-white leading-loose lg:pt-0 pt-8 lg:w-5/12 w-full">
          <div className="divcontent flex-col flex">
            <img src={Public} alt="loading..." className="w-12 mb-8" />
            <h3 className="text-2xl font-bold">{h1 ? h1 : "Certified for Acceptance in Public and Private Institutions"}</h3>
            <p className="font-light font-opensans py-10">
              {`${
                p1
                  ? p1
                  : "Our certified translations are guaranteed for acceptance in public and private institutions such as the United States Citizenship and  Immigration Services (USCIS). If you need NAATI-certified translators, we can provide them. If you need certifications forthe UK, we can also provide them. Our certified translations canbe accepted in virtually any country."
              }`}
            </p>
            {linkName ? (
              <a href={`${scrollTo ? scrollTo : ""}`} className="border-b learnmore border-textgray hover:text-orange max-w-max mb-8">
                GET STARTED
              </a>
            ) : null}
          </div>
          <div className="divcontent flex-col flex">
            <img src={Six} alt="loading..." className="w-12 mb-8" />
            <h3 className="text-2xl font-bold">{h2 ? h2 : "Certified In Over Six Continents"}</h3>
            <p className="font-light py-10 font-opensans">
              {`${
                p2
                  ? p2
                  : "Our global network of more than 20,000 translators in sixcontinents enables us to certify your documents, backed up by our 24/7 customer support. We strategically hire native certified translators who are in different time zones to prepare for your needs, so you can be accommodated anytime, anywhere."
              }`}
            </p>
            {linkName ? (
              <a href={`${scrollTo ? scrollTo : ""}`} className="border-b learnmore border-textgray hover:text-orange max-w-max mb-8">
                GET STARTED
              </a>
            ) : null}
          </div>
          <div className="divcontent flex-col flex">
            <img src={Bank} alt="loading..." className="w-12 mb-8" />
            <h3 className="text-2xl font-bold">{h3 ? h3 : "Certified by the ATA, ISO, and You"}</h3>
            <p className="font-light font-opensans py-10">
              {`${
                p3
                  ? p3
                  : "Tomedes is verily certified by the American Translator's Association and the International Organization for Standardization. Our translators, meanwhile, are also certified by translator's associations in their various native countries. We guide you through the process of requirements, so you can be sure your certified translations are accepted. You can be the judge of our translations, as soon as you get started with us."
              }`}
            </p>
            {linkName ? (
              <a href={`${scrollTo ? scrollTo : ""}`} className="border-b learnmore border-textgray hover:text-orange max-w-max mb-8">
                GET STARTED
              </a>
            ) : null}
          </div>
          <div className="divcontent flex-col flex">
            {img4 ? <img src={img4} alt="loading..." className="my-3 w-12" /> : null}
            <h3 className="text-2xl font-bold font-secondary">{h4 ? h4 : " "}</h3>
            <p className="font-light font-opensans py-10">{`${p4 ? p4 : " "}`}</p>
            {linkName ? (
              <a href={`${scrollTo ? scrollTo : ""}`} className="border-b learnmore border-textgray hover:text-orange max-w-max mb-8">
                GET STARTED
              </a>
            ) : null}
          </div>
          <div className="divcontent flex-col flex">
            {img5 ? <img src={img5} alt="loading..." className="my-3 w-12" /> : null}
            <h3 className="text-2xl font-bold font-secondary">{h5 ? h5 : " "}</h3>
            <p className="font-light font-opensans py-10">{`${p5 ? p5 : " "}`}</p>
            {linkName ? (
              <a href={`${scrollTo ? scrollTo : ""}`} className="border-b learnmore border-textgray hover:text-orange max-w-max mb-8">
                GET STARTED
              </a>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
}
