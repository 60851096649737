import React from "react";

function Birthcomp({ backgroundCol  , firstHead , midHead , secondHead , para, colorHead, linkName}) {
  return (
    <div
      className={`flex items-center justify-center ${
        backgroundCol ? backgroundCol : "bg-birth-banner"
      } bg-cover bg-center bg-no-repeat py-20 md:py-24`}
    >
      <div className="grid grid-cols-1 xl:grid-cols-2 max-w-screen-xl px-4 md:px-28">
        <div>
          <h2 className="text-3xl md:text-4xl font-secondary font-bold md:leading-[50px] leading-10 mb-5 md:mb-10">
            <span className="text-white ">{firstHead ? firstHead : "Birth Certificate Translation"}</span>{" "}
            <span className={`${colorHead ? colorHead : "text-lightBlue"} `}>{midHead ? midHead : "Trusted"}</span>{" "}
            <span className="text-white ">{secondHead ? secondHead : "by Individuals Worldwide"}</span>
          </h2>
          <p className="text-darkMist font-opensans leading-8 pb-6">
            {para ? para : " We aspire to provide the highest quality birth certificate translation services helping you reach your global objectives. We support certified birth certificate translations that align with your goals for travel, immigration, and study abroad."}
           
          </p>
          {linkName ? <a href="" className="border-b border-textgray text-xl learnmore-cn text-white hover:text-orange">SEE OUR SUPPORTED LANGUAGES</a> : null}
        </div>
        <div></div>
      </div>
    </div>
  );
}

export default Birthcomp;
